import type { SVGProps } from "react";
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#6ABB7B"
      d="m8.248 14.771-3.943-3.652a.972.972 0 0 1-.082-1.327l.611-.734a.863.863 0 0 1 1.263-.086l2.579 2.382 5.063-6.034a.863.863 0 0 1 1.262-.085l.7.642a.972.972 0 0 1 .081 1.326l-6.271 7.482a.863.863 0 0 1-1.263.086Z"
    />
  </svg>
);
export default SvgCheck;
