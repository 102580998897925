import classNames from "classnames";
import type { Tag as TTag } from "schemas";
import { Button } from "../Button";
import { Card } from "../Card";
import type { IconType } from "../Icon";
import { Tag } from "../Tag";
import {
  displayStyles,
  flexStyles,
  imageStyles,
  overflowStyles,
  paddingStyles,
  textStyles,
  widthStyles,
} from "../styles";
import type { PropsWithLabel, PropsWithOptionalDisabled } from "../types";
import { Markdown, TextAccent } from "../typography";

interface Props extends PropsWithLabel, PropsWithOptionalDisabled {
  header: string;
  description?: string;
  onClick: () => Promise<void>;
  image?: string;
  tags: TTag[];
}
export function AppointmentCard({ label, header, tags, description, image, onClick, disabled = false }: Props) {
  return (
    <Card className={classNames(textStyles.neutral500, paddingStyles.pb125, overflowStyles.hidden)}>
      {image && (
        <picture>
          <source srcSet={`${image}/feed`} type="image/webp" />
          <img
            className={classNames(imageStyles.fitCover, displayStyles.block, widthStyles.full)}
            src={`${image}/feed`}
            alt={header}
          />
        </picture>
      )}
      <div className={classNames(flexStyles.vert050, paddingStyles.px125, { [paddingStyles.pt125]: !image })}>
        <TextAccent>{header}</TextAccent>
        <div className={flexStyles.horiz062}>
          {tags.map(({ label, iconType }) => (
            <Tag key={`appt-${label}`} label={label} iconType={iconType as IconType} />
          ))}
        </div>
        <Markdown size="small">{description}</Markdown>
        <Button themed size="medium" disabled={disabled} onClick={onClick}>
          {label}
        </Button>
      </div>
    </Card>
  );
}
