import classNames from "classnames";
import type { PersistedContact } from "schemas";
import { type IconType, type PropsWithClassName, Tag, flexStyles, tagStyles } from "ui";

type TagSource = {
  iconType: IconType;
  label: string;
};

interface Props extends PropsWithClassName {
  contact: PersistedContact;
}
export function ContactTags({ contact, className }: Props) {
  const { pending, confirmed, bounced } = contact;

  const tags: TagSource[] = [];

  if (pending) {
    tags.push({
      iconType: "Pending",
      label: "Pending",
    });
  }

  if (bounced) {
    tags.push({
      iconType: "X",
      label: "Bounced",
    });
  }

  if (!confirmed && !pending) {
    tags.push({
      iconType: "EmailNewsletter",
      label: "Unconfirmed",
    });
  }

  return (
    <div className={classNames(flexStyles.horiz050, flexStyles.wrap)}>
      {tags.map(({ iconType, label }) => (
        <Tag
          key={`${iconType}-${label}`}
          className={classNames(tagStyles.neutral200, className)}
          iconType={iconType}
          label={label}
          type="Square"
        />
      ))}
    </div>
  );
}
