import classNames from "classnames";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router";
import {
  type BlockDestination,
  InlineEditableBlockKindValues,
  type PersistedElement,
  type PersistedInlineEditableBlock,
} from "schemas";
import { BlockCard, PopoverMenu, flexStyles } from "ui";
import type { PropsWithDirtyBlock } from "../../helpers";
import { linksForElement } from "../../helpers/linksForElement";
import { useBlockKindParam, useIdParam } from "../../hooks";
import { useBlock } from "../../store";
import { EditInlineBlock } from "../ui/InlineEditors";
import { EditWrapper } from "./EditWrapper";

interface Props extends PropsWithDirtyBlock {
  last: boolean;
  destination: BlockDestination;
  element: PersistedElement;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
}
export function BlockElement({ last, element, destination, dragHandleProps, dirtyBlock, setDirtyBlock }: Props) {
  const { id, position, kind, block: elementBlock } = element;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const block = useBlock(kind, elementBlock.id);
  const openBlockId = useIdParam("blockId");
  const pathKind = useBlockKindParam();
  const drawerHack = pathname.match(/new$/);
  const hasDrawer = drawerHack && drawerHack.length > 0;

  if (!block) {
    return null;
  }

  const disabledDestination = destination.type === "Campaign" && destination.entity.status !== "Draft";

  const open = block.id === openBlockId && kind === pathKind && !hasDrawer;
  const onClick = () => {
    if (!dirtyBlock && !disabledDestination) {
      const to = open ? "." : `blocks/${block.kind}/${block.id}`;
      navigate(to, { relative: "path", preventScrollReset: true });
    }
  };

  const card = (
    <BlockCard
      key={id}
      block={block}
      disabled
      lazy={false}
      navigate={async (path: string) => {
        navigate(path);
      }}
    />
  );
  const editElement = open ? (
    InlineEditableBlockKindValues.includes(block.kind) ? (
      <EditInlineBlock
        elementId={element.id}
        block={block as PersistedInlineEditableBlock}
        destination={destination}
        closeItself={() => {
          navigate(".");
          setDirtyBlock(false);
        }}
        dirtyBlock={dirtyBlock}
        setDirtyBlock={setDirtyBlock}
      />
    ) : (
      <>
        {card}
        <PopoverMenu open links={linksForElement(destination, block, element.id, navigate)} />
      </>
    )
  ) : undefined;

  return (
    <EditWrapper
      className={classNames(flexStyles.alignStretch)}
      index={position}
      last={last}
      kind={kind}
      blockId={block.id}
      dragHandleProps={dragHandleProps}
      open={open}
      onClick={onClick}
      editElement={editElement}
    >
      {card}
    </EditWrapper>
  );
}
