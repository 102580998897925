import type { Path } from "react-hook-form";
import { Help, ImageInput, InlineTextInput, type PropsWithOptionalPrefix, iconStyles, prefixedName } from "ui";
import { uploadImage } from "../../../store";

interface Props<T> extends PropsWithOptionalPrefix {
  widthName?: string;
  onSubmit: (payload: T) => Promise<void>;
}

export function ImageBlockEditor<T extends object>({ onSubmit, prefix, widthName }: Props<T>) {
  const altHelp = (
    <Help className={iconStyles.size100}>Alt text is used by screen readers and when the image fails to load.</Help>
  );
  return (
    <>
      <ImageInput
        widthName={widthName}
        urlValue
        uploadImage={uploadImage}
        name={prefixedName("image", prefix) as Path<T>}
      />
      <InlineTextInput<T>
        label="Image link URL"
        size="Text"
        name={prefixedName("url", prefix) as Path<T>}
        placeholder="URL"
        onSubmit={onSubmit}
      />
      <InlineTextInput<T>
        label="Image caption"
        size="Text"
        name={prefixedName("caption", prefix) as Path<T>}
        placeholder="Image caption"
        onSubmit={onSubmit}
      />
      <InlineTextInput<T>
        label="Image alt text"
        size="Text"
        name={prefixedName("alt", prefix) as Path<T>}
        placeholder="Image's alt text"
        onSubmit={onSubmit}
        extra={altHelp}
      />
    </>
  );
}
