import classNames from "classnames";
import type { PersistedCampaign } from "schemas";
import { Loader, flexStyles, heightStyles, imageStyles, widthStyles } from "ui";
import { useCampaignScreenshot } from "../../store";

interface Props {
  campaign: PersistedCampaign;
}

export function CampaignScreenshot({ campaign }: Props) {
  const { url, loading } = useCampaignScreenshot(campaign.id);

  if (loading) {
    return (
      <div
        className={classNames(
          widthStyles.width750,
          heightStyles.full,
          flexStyles.vert,
          flexStyles.shrink0,
          flexStyles.alignCenter,
          flexStyles.justifyCenter,
        )}
      >
        <Loader fitted />
      </div>
    );
  }

  if (!url) {
    return null;
  }

  return (
    <div className={classNames(widthStyles.width750, flexStyles.shrink0, heightStyles.height1250)}>
      <img
        className={classNames(imageStyles.fitCover, imageStyles.positionTop, widthStyles.full, heightStyles.full)}
        src={url}
        alt="Campaign Screenshot"
      />
    </div>
  );
}
