import classNames from "classnames";
import { bgStyles, borderStyles, flexStyles, overflowStyles, paddingStyles, positionStyles } from "../../styles";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarDivider } from "./ToolbarDivider";
import { ToolbarTextAlign } from "./ToolbarTextAlign";

interface Props {
  textAlignmentSelectable: boolean;
  textAlignmentName: string;
}
export function BottomToolbarPlugin({ textAlignmentSelectable, textAlignmentName }: Props) {
  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        flexStyles.alignCenter,
        bgStyles.editorBackground,
        borderStyles.topEditorBorder,
        overflowStyles.autoX,
        positionStyles.absolute,
        positionStyles.bottom006,
        positionStyles.left006,
        positionStyles.right006,
        paddingStyles.py025,
        paddingStyles.px050,
      )}
    >
      {textAlignmentSelectable && (
        <>
          <ToolbarTextAlign name={textAlignmentName} />
          <ToolbarDivider />
        </>
      )}
      <ToolbarButton type="bold" />
      <ToolbarButton type="italic" />
      <ToolbarButton type="strikethrough" />
      <ToolbarDivider />
      <ToolbarButton type="unordered-list" />
      <ToolbarButton type="ordered-list" />
    </div>
  );
}
