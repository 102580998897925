"use client";

import classNames from "classnames";
import { type CSSProperties, type MouseEventHandler, useState } from "react";
import { Icon } from "./Icon";
import styles from "./IconButton.module.css";
import { Loader } from "./Loader";
import { bgStyles, borderStyles, flexStyles, paddingStyles, pointerStyles, widthStyles } from "./styles";
import type {
  OnClickHandler,
  PropsWithClassName,
  PropsWithIconType,
  PropsWithOptionalClick,
  PropsWithOptionalLabel,
} from "./types";

interface Props extends PropsWithClassName, PropsWithOptionalClick, PropsWithIconType, PropsWithOptionalLabel {
  tabIndex?: number;
  square?: boolean;
  style?: CSSProperties;
  wide?: boolean;
  withoutLoader?: boolean;
}

export function IconButton({
  className,
  onClick,
  iconType,
  label,
  square = false,
  tabIndex,
  style,
  wide = false,
  withoutLoader = false,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  function wrapClick(callback: OnClickHandler | undefined): MouseEventHandler {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (callback && !loading) {
        setLoading(true);
        Promise.resolve(callback()).then(() => {
          setLoading(false);
        });
      }
    };
  }

  if (!withoutLoader && loading) {
    return <Loader fitted className={classNames(paddingStyles.px050, styles.loader)} />;
  }

  return (
    <button
      title={label}
      style={style}
      className={classNames(
        flexStyles.horiz,
        flexStyles.justifyCenter,
        flexStyles.alignCenter,
        bgStyles.initialNone,
        borderStyles.initialNone,
        styles.button,
        { [borderStyles.radiusRound]: !square, [borderStyles.radius025]: square, [pointerStyles.cursor]: !!onClick },
        className,
      )}
      type="button"
      tabIndex={tabIndex}
      onClick={wrapClick(onClick)}
    >
      <Icon className={classNames({ [widthStyles.full]: wide })} iconType={iconType} />
    </button>
  );
}
