/// <reference types="react/canary" />
import { autoUpdate, flip, offset, shift, useFloating } from "@floating-ui/react-dom";
import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { bgStyles, borderStyles, paddingStyles, widthStyles } from "./styles";
import type { PropsWithClassName } from "./types";

interface Props extends PropsWithChildren, PropsWithClassName {
  popoverId: string;
}
export function Popover({ children, popoverId, className }: Props) {
  const { refs, floatingStyles } = useFloating({
    middleware: [flip(), shift(), offset({ mainAxis: 16 })],
    whileElementsMounted: autoUpdate,
  });
  return (
    <>
      {/* @ts-expect-error popover */}
      <button ref={refs.setReference} type="button" popovertarget={popoverId} className={classNames(className)} />
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        className={classNames(paddingStyles.p075, widthStyles.maxWidth900, bgStyles.neutral0, borderStyles.radius050)}
        popover="auto"
        id={popoverId}
      >
        {children}
      </div>
    </>
  );
}
