import classNames from "classnames";
import type { SharedBusiness } from "schemas";
import { Button } from "../Button";
import { type PopoverLinkItem, PopoverMenu } from "../PopoverMenu";
import { bgStyles, flexStyles, overflowStyles, paddingStyles, positionStyles, textStyles } from "../styles";
import type { OnClickHandler, PropsWithClassName, PropsWithOptionalClick } from "../types";
import { TextMain } from "../typography";
import { Logo } from "./Logo";

interface Props extends PropsWithClassName, PropsWithOptionalClick {
  business: SharedBusiness;
  onHide?: OnClickHandler;
  disabled?: boolean;
}
export function GetYours({ className, business, onClick, onHide, disabled = false }: Props) {
  if (business.paidFeatures.noBranding) {
    return null;
  }

  const link: PopoverLinkItem = {
    onClick: onHide || (() => {}),
    label: "Hide",
    iconType: "Hide",
  };

  return (
    <div
      className={classNames(
        bgStyles.callout,
        paddingStyles.p200,
        paddingStyles.px100,
        positionStyles.relative,
        overflowStyles.hidden,
        textStyles.callout,
        textStyles.center,
        flexStyles.alignSelfStretch,
        flexStyles.vert100,
        flexStyles.alignCenter,
        className,
      )}
    >
      <Logo />
      <TextMain>Really simple email newsletters</TextMain>
      {onClick && (
        <Button disabled={disabled} onClick={onClick}>
          Get started for free
        </Button>
      )}
      {onHide && <PopoverMenu open links={[link]} />}
    </div>
  );
}
