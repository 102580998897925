import type { SVGProps } from "react";
const SvgTextFields = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="text_fields_svg__a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
        shapeRendering: "crispEdges",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#text_fields_svg__a)">
      <path fill="#F0A056" d="M6 16V6H2V4h10v2H8v10H6Zm7.5 0v-6H11V8h7v2h-2.5v6h-2Z" />
    </g>
  </svg>
);
export default SvgTextFields;
