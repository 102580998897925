import classNames from "classnames";
import type { CampaignStatus, PersistedCampaign } from "schemas";
import { Icon, type IconType, TextMain, flexStyles, iconStyles, textStyles } from "ui";

interface Props {
  campaign: PersistedCampaign;
}

export function CampaignStatusLabel({ campaign }: Props) {
  const successStatuses: CampaignStatus[] = ["Sent", "Sending"];
  const warningStatuses: CampaignStatus[] = ["Cancelled"];
  return (
    <div
      className={classNames(flexStyles.horiz025, iconStyles.textColor, {
        [textStyles.successMedium]: successStatuses.includes(campaign.status),
        [textStyles.warningMedium]: warningStatuses.includes(campaign.status),
        [textStyles.secondary500]: ![...successStatuses, ...warningStatuses].includes(campaign.status),
      })}
    >
      <TextMain>{campaign.status}</TextMain>
      <Icon iconType={StatusIcons[campaign.status]} />
    </div>
  );
}

const StatusIcons: Record<CampaignStatus, IconType> = {
  Draft: "Edit",
  Cancelled: "X",
  Scheduled: "Pending",
  Sending: "Pending",
  Sent: "Check",
};
