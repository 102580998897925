import { useRef, useState } from "react";
import type { CampaignStatType } from "schemas";
import { SubheaderMain, TextMain } from "ui";
import { SuperCard } from "../ui";
import { CampaignStatEmails } from "./CampaignStatEmails";

interface Props {
  label: string;
  count: number;
  statType?: CampaignStatType;
}

export function CampaignStat({ label, count, statType }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const openable = !!statType && count > 0;
  return (
    <SuperCard
      title={<TextMain>{label}</TextMain>}
      right={<SubheaderMain>{count.toLocaleString()}</SubheaderMain>}
      headerOnClick={openable ? () => setOpen(!open) : undefined}
    >
      {openable && open && (
        <div ref={ref}>
          <CampaignStatEmails width={ref.current?.clientWidth} statType={statType} />
        </div>
      )}
    </SuperCard>
  );
}
