import type { SVGProps } from "react";

const SvgBasics = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="Basics_svg__a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={20}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#Basics_svg__a)">
      <path
        d="M5.5 19c-.417 0-.77-.146-1.062-.438A1.444 1.444 0 0 1 4 17.5v-15c0-.417.146-.77.438-1.062A1.444 1.444 0 0 1 5.5 1h9c.417 0 .77.146 1.062.438.292.291.438.645.438 1.062v15c0 .417-.146.77-.438 1.062A1.444 1.444 0 0 1 14.5 19h-9Zm0-2.5v1h9v-1h-9Zm0-13h9v-1h-9v1Zm4.521 10c-.82 0-1.608.132-2.365.396A8.56 8.56 0 0 0 5.521 15h8.958a7.639 7.639 0 0 0-2.114-1.115 7.253 7.253 0 0 0-2.344-.385ZM10 12c.792 0 1.57.1 2.333.302a8.478 8.478 0 0 1 2.167.906V5h-9v8.208a8.478 8.478 0 0 1 2.167-.906A9.111 9.111 0 0 1 10 12Zm0-1c.695 0 1.285-.243 1.771-.729A2.411 2.411 0 0 0 12.5 8.5c0-.695-.243-1.285-.729-1.771A2.411 2.411 0 0 0 10 6c-.695 0-1.285.243-1.771.729A2.411 2.411 0 0 0 7.5 8.5c0 .695.243 1.285.729 1.771A2.411 2.411 0 0 0 10 11Zm0-1.5a.962.962 0 0 1-.708-.292A.962.962 0 0 1 9 8.5c0-.278.097-.514.292-.708A.962.962 0 0 1 10 7.5c.278 0 .514.097.708.292.195.194.292.43.292.708a.962.962 0 0 1-.292.708A.962.962 0 0 1 10 9.5Z"
        fill="#F0A056"
      />
    </g>
  </svg>
);
export default SvgBasics;
