import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { textStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props extends PropsWithClassName, PropsWithChildren {}
export function Huge({ children, className }: Props) {
  return (
    <div className={classNames(textStyles.lsColor, textStyles.size250, textStyles.weight500, className)}>
      {children}
    </div>
  );
}

export function HugeAccent({ children, className }: Props) {
  return <div className={classNames(textStyles.weight700, className)}>{children}</div>;
}
