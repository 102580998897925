"use client";

import classNames from "classnames";
import type { BaseSyntheticEvent, MouseEventHandler, PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";
import { flexStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props<T> extends PropsWithClassName, PropsWithChildren {
  onSubmit: (values: T, event?: BaseSyntheticEvent) => Promise<void>;
  onClick?: MouseEventHandler;
  resetOnSubmit?: boolean;
}
export function FormTag<T extends object>({ className, children, onSubmit, onClick, resetOnSubmit }: Props<T>) {
  const { handleSubmit, reset } = useFormContext<T>();

  const localSubmit = async (values: T, event?: BaseSyntheticEvent) => {
    await onSubmit(values, event);
    resetOnSubmit && reset();
  };

  return (
    <form className={classNames(flexStyles.vert125, className)} onClick={onClick} onSubmit={handleSubmit(localSubmit)}>
      {children}
    </form>
  );
}
