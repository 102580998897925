"use client";

import type { ChangeEvent } from "react";
import type {
  PropsWithClassName,
  PropsWithName,
  PropsWithOptionalLabel,
  PropsWithOptionalPlaceholder,
} from "../../types";
import styles from "../forms.module.css";
import { TextInput } from "./TextInput";

interface Props extends PropsWithClassName, PropsWithName, PropsWithOptionalLabel, PropsWithOptionalPlaceholder {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
export function SmallTextInput({ name, placeholder, label, className, onChange }: Props) {
  return (
    <TextInput
      placeholder={placeholder}
      inputClassName={styles.small}
      className={className}
      name={name}
      label={label}
      onChange={onChange}
    />
  );
}
