import type { SVGProps } from "react";
const SvgFormatHeading = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <mask
      id="format_h1_svg__a"
      width={20}
      height={20}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#format_h1_svg__a)">
      <path fill="#F0A056" d="M4 14V6h1.5v3.25H9V6h1.5v8H9v-3.25H5.5V14H4Zm10.5 0V7.5h-2V6H16v8h-1.5Z" />
    </g>
  </svg>
);
export default SvgFormatHeading;
