"use client";

import { type FieldError, useFormContext } from "react-hook-form";
import type { PropsWithName } from "../../types";
import { SimpleError } from "./SimpleError";

// biome-ignore lint:
const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, part) => acc?.[part], obj);
};

export function Error({ name }: PropsWithName) {
  const {
    formState: { errors },
  } = useFormContext();
  const error = getNestedValue(errors, name) as FieldError;

  if (!error) {
    return null;
  }

  return <SimpleError>{error.message?.toString()}</SimpleError>;
}
