import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { textStyles } from "../../styles";
import type { PropsWithClassName } from "../../types";
import { Description } from "../../typography";

interface Props extends PropsWithClassName, PropsWithChildren {}
export function SimpleError({ children, className }: Props) {
  if (!children) {
    return null;
  }

  return <Description className={classNames(textStyles.errorDark, className)}>{children}</Description>;
}
