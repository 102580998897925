import classNames from "classnames";
import { toast } from "react-toastify";
import type { HobDnsRecord } from "schemas";
import { Card, Code, DescriptionAccent, Icon, flexStyles, iconStyles, paddingStyles } from "ui";
import { validationIcon } from "./validationIcon";
import { verificationDescription } from "./verificationDescription";

interface Props {
  record: HobDnsRecord;
}
export function DnsRecord({ record: { label, host, value, valid, type } }: Props) {
  const onCopy = () => {
    toast.success("Copied to clipboard", { autoClose: 500 });
  };

  return (
    <Card className={classNames(flexStyles.vert125, paddingStyles.p125)}>
      <DescriptionAccent>{label}</DescriptionAccent>
      <div className={flexStyles.vert025}>
        <DescriptionAccent>Record Type</DescriptionAccent>
        <Code>{type.toUpperCase()}</Code>
      </div>
      <div className={flexStyles.vert025}>
        <DescriptionAccent>Host</DescriptionAccent>
        <Code copyable onCopy={onCopy}>
          {host}
        </Code>
      </div>
      <div className={flexStyles.vert025}>
        <DescriptionAccent>Value</DescriptionAccent>
        <Code copyable onCopy={onCopy}>
          {value}
        </Code>
      </div>
      <div
        className={classNames(flexStyles.horiz050, flexStyles.alignCenter, {
          [iconStyles.successMedium]: valid,
          [iconStyles.warningMedium]: !valid,
        })}
      >
        <Icon iconType={validationIcon(valid)} />
        <DescriptionAccent>{verificationDescription(valid)}</DescriptionAccent>
      </div>
    </Card>
  );
}
