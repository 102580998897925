import type { SVGProps } from "react";

const SvgAddImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={81} height={80} viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#AddImage_svg__a)">
      <path
        d="M23.95 64.01h-7.62c-.47 0-.9-.28-1.09-.7-.19-.43-.12-.93.19-1.29l17.84-20.44c.23-.27.59-.41.92-.41.35 0 .68.16.91.43l3.85 4.63c.42.51.35 1.27-.16 1.69-.51.42-1.27.35-1.69-.16l-2.95-3.55L18.97 61.6h4.98c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2v.01Z"
        fill="#B0F3EF"
      />
      <path
        d="M64.64 69.75H16.75c-3.86 0-7-3.14-7-7V17c0-3.86 3.14-7 7-7h47.89c3.86 0 7 3.14 7 7v45.75c0 3.86-3.14 7-7 7ZM16.75 13c-2.21 0-4 1.79-4 4v45.75c0 2.21 1.79 4 4 4h47.89c2.21 0 4-1.79 4-4V17c0-2.21-1.79-4-4-4H16.75Z"
        fill="#0FD6CA"
      />
      <path
        d="M64.58 64.01H23.95c-.47 0-.9-.28-1.09-.71-.19-.43-.12-.94.2-1.29l23.28-26.16c.25-.28.61-.42.98-.4.37.03.71.22.92.53L65.6 62.14c.24.37.27.84.06 1.23-.21.39-.62.63-1.06.63l-.02.01Zm-37.96-2.4h35.73L47.09 38.62 26.62 61.61Z"
        fill="#0FD6CA"
      />
      <path
        d="M25.36 32.86c-4.24 0-7.69-3.45-7.69-7.69s3.45-7.69 7.69-7.69 7.69 3.45 7.69 7.69-3.45 7.69-7.69 7.69Z"
        fill="#B0F3EF"
      />
      <path
        d="M25.36 32.86c-4.24 0-7.69-3.45-7.69-7.69s3.45-7.69 7.69-7.69 7.69 3.45 7.69 7.69-3.45 7.69-7.69 7.69Zm0-12.99c-2.92 0-5.29 2.37-5.29 5.29s2.37 5.29 5.29 5.29 5.29-2.37 5.29-5.29-2.37-5.29-5.29-5.29Z"
        fill="#0FD6CA"
      />
    </g>
    <defs>
      <clipPath id="AddImage_svg__a">
        <path fill="#fff" transform="translate(9.75 10)" d="M0 0h61.89v59.75H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAddImage;
