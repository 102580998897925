import type { SVGProps } from "react";
const SvgCheckboxChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <rect width={22} height={22} viewBox="0 0 22 22" x={1} y={1} stroke="#848C8F" strokeWidth={2} rx={3} />
    <g clipPath="url(#CheckboxChecked_svg__a)">
      <path
        fill="#F38F2C"
        d="M10.25 16.772 6.305 13.12a.97.97 0 0 1-.082-1.327l.611-.735a.863.863 0 0 1 1.263-.085l2.579 2.382 5.063-6.034a.863.863 0 0 1 1.263-.086l.699.642a.97.97 0 0 1 .081 1.327l-6.272 7.482a.863.863 0 0 1-1.262.086"
      />
    </g>
    <defs>
      <clipPath id="CheckboxChecked_svg__a">
        <path fill="#fff" d="M6 7h12v10H6z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckboxChecked;
