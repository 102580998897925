import classNames from "classnames";
import type { MouseEvent, PropsWithChildren } from "react";
import { bgStyles, borderStyles, iconStyles, outlineStyles, paddingStyles, pointerStyles, textStyles } from "./styles";
import type { PropsWithClassName, PropsWithOnClick } from "./types";

interface Props extends PropsWithClassName, PropsWithChildren, PropsWithOnClick {
  stopPropagation?: boolean;
  size?: "small" | "normal";
}

export function Link({ children, onClick, className, size, stopPropagation }: Props) {
  const neuterClick = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    e.preventDefault();
    onClick();
  };

  return (
    <button
      className={classNames(
        { [textStyles.size087]: size === "normal", [textStyles.size062]: size === "small" },
        textStyles.weight600,
        textStyles.left,
        textStyles.lsColor,
        textStyles.underline,
        textStyles.neutral400Hover,
        iconStyles.textColor,
        borderStyles.none,
        bgStyles.initialNone,
        paddingStyles.p0,
        pointerStyles.cursor,
        outlineStyles.primary,
        className,
      )}
      type="button"
      onClick={neuterClick}
    >
      {children}
    </button>
  );
}
