import { imageStyles } from "../styles";

export function Logo() {
  return (
    <svg
      width="240"
      height="72"
      className={imageStyles.fitContain}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 909.5 278.68"
    >
      <g>
        <circle
          style={{
            fill: "var(--litesite-callout-text, #231f20)",
          }}
          cx="136.41"
          cy="136.68"
          r="136.41"
        />
        <circle
          style={{
            fill: "#ff6803",
          }}
          cx="136.41"
          cy="136.68"
          r="122.72"
        />
        <path
          style={{
            fill: "#f1f2f2",
          }}
          d="M237.62,5.63c38.49,0,55.71,18.23,55.71,42.54,0,49.3-70.57,123.58-175.92,123.58-5.06,0-9.79,0-14.86-.34-21.27,39.17-47.95,77.32-89.14,101.63-2.7,0-3.38-.68-3.38-1.69,0-1.35,2.36-3.38,2.36-3.38C64.74,203.16,117.75,5.63,237.62,5.63ZM107.28,166.69c96.23-.68,148.57-71.92,148.57-115.82,0-19.58-10.47-33.43-31.74-33.43-58.75,0-80.02,75.97-118.85,149.24h2.03Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #231f20)",
          }}
          d="M13.41,278.68c-8.57,0-9-6.09-9-7.32,0-3.03,2.03-5.57,3.9-7.27,14.5-18.11,29.64-47.72,45.66-79.06C96.12,102.6,148.56,0,237.62,0c19.74,0,35.18,4.53,45.89,13.46,10.11,8.42,15.45,20.42,15.45,34.71,0,27.19-19.37,59.63-50.56,84.67-35.78,28.72-82.3,44.54-130.99,44.54-3.94,0-7.71,0-11.62-.16-18.54,33.67-45.42,74.65-89.52,100.67l-1.32.78h-1.54ZM224.11,23.07c-19.56,0-35.9,9.22-51.41,29.02-14.6,18.64-27.12,44.86-40.38,72.61-5.65,11.82-11.45,23.97-17.69,36.16,19.25-.9,37.51-4.79,54.35-11.6,16.83-6.81,31.91-16.36,44.84-28.39,11.45-10.66,20.89-23.1,27.31-35.99,5.95-11.93,9.09-23.69,9.09-34.01s-3.39-27.8-26.11-27.8Z"
        />
      </g>
      <g>
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M424.07,142.8v20.45h-14.2v-61.19h21.59c4.31,0,8.01.51,11.11,1.53,3.09,1.02,5.64,2.43,7.64,4.22,2,1.79,3.48,3.91,4.43,6.34.95,2.44,1.43,5.07,1.43,7.9,0,3.05-.49,5.85-1.47,8.4-.98,2.55-2.48,4.73-4.49,6.55-2.02,1.82-4.57,3.24-7.67,4.26-3.09,1.02-6.76,1.53-10.98,1.53h-7.39ZM424.07,132.09h7.39c3.7,0,6.36-.88,7.98-2.65,1.62-1.76,2.44-4.23,2.44-7.39,0-1.4-.21-2.67-.63-3.82-.42-1.15-1.06-2.13-1.91-2.96-.85-.83-1.93-1.46-3.23-1.91-1.3-.45-2.85-.67-4.64-.67h-7.39v19.4Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M537.97,132.64c0,4.42-.76,8.54-2.29,12.35-1.53,3.81-3.68,7.12-6.45,9.93-2.77,2.81-6.11,5.02-10.02,6.61-3.91,1.6-8.24,2.39-13,2.39s-9.1-.8-13.02-2.39c-3.92-1.6-7.27-3.8-10.06-6.61-2.79-2.81-4.94-6.12-6.47-9.93-1.53-3.81-2.29-7.92-2.29-12.35s.76-8.54,2.29-12.35c1.53-3.81,3.68-7.11,6.47-9.91,2.79-2.8,6.14-5,10.06-6.59,3.92-1.6,8.26-2.39,13.02-2.39s9.09.81,13,2.42c3.91,1.61,7.25,3.82,10.02,6.62,2.77,2.8,4.92,6.1,6.45,9.91,1.53,3.81,2.29,7.91,2.29,12.31ZM523.44,132.64c0-3.02-.39-5.75-1.18-8.17-.78-2.42-1.91-4.47-3.38-6.15-1.47-1.68-3.27-2.97-5.4-3.86-2.13-.9-4.55-1.34-7.27-1.34s-5.19.45-7.33,1.34c-2.14.9-3.95,2.18-5.42,3.86-1.47,1.68-2.6,3.73-3.38,6.15-.78,2.42-1.18,5.15-1.18,8.17s.39,5.79,1.18,8.21c.78,2.42,1.91,4.47,3.38,6.15,1.47,1.68,3.28,2.96,5.42,3.84,2.14.88,4.58,1.32,7.33,1.32s5.14-.44,7.27-1.32c2.13-.88,3.93-2.16,5.4-3.84,1.47-1.68,2.6-3.73,3.38-6.15.78-2.42,1.18-5.16,1.18-8.21Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M575.14,142.8v20.45h-14.2v-61.19h21.59c4.31,0,8.01.51,11.11,1.53,3.09,1.02,5.64,2.43,7.64,4.22,2,1.79,3.48,3.91,4.43,6.34.95,2.44,1.43,5.07,1.43,7.9,0,3.05-.49,5.85-1.47,8.4-.98,2.55-2.48,4.73-4.49,6.55-2.02,1.82-4.57,3.24-7.67,4.26-3.09,1.02-6.76,1.53-10.98,1.53h-7.39ZM575.14,132.09h7.39c3.7,0,6.36-.88,7.98-2.65,1.62-1.76,2.44-4.23,2.44-7.39,0-1.4-.21-2.67-.63-3.82-.42-1.15-1.06-2.13-1.91-2.96-.85-.83-1.93-1.46-3.23-1.91-1.3-.45-2.85-.67-4.64-.67h-7.39v19.4Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M662.79,114.49c-.42.67-.86,1.18-1.32,1.51s-1.06.5-1.79.5c-.64,0-1.34-.2-2.08-.61-.74-.41-1.58-.86-2.52-1.37-.94-.5-2.01-.96-3.21-1.36-1.2-.41-2.58-.61-4.12-.61-2.66,0-4.64.57-5.94,1.7-1.3,1.13-1.95,2.67-1.95,4.6,0,1.23.39,2.25,1.18,3.07.78.81,1.81,1.51,3.09,2.1,1.27.59,2.73,1.13,4.37,1.62,1.64.49,3.31,1.04,5.02,1.64,1.71.6,3.38,1.31,5.02,2.12,1.64.81,3.09,1.85,4.37,3.11,1.27,1.26,2.3,2.79,3.09,4.6s1.18,3.98,1.18,6.53c0,2.83-.49,5.47-1.47,7.94-.98,2.46-2.4,4.61-4.26,6.45-1.86,1.83-4.16,3.28-6.89,4.33-2.73,1.05-5.83,1.57-9.3,1.57-1.9,0-3.84-.2-5.82-.59-1.97-.39-3.89-.94-5.73-1.66-1.85-.71-3.58-1.56-5.21-2.54-1.62-.98-3.04-2.07-4.24-3.28l4.2-6.64c.34-.48.78-.88,1.32-1.2.55-.32,1.14-.48,1.79-.48.84,0,1.69.27,2.54.8.85.53,1.82,1.12,2.9,1.76,1.08.64,2.32,1.23,3.72,1.76,1.4.53,3.05.8,4.96.8,2.58,0,4.58-.57,6.01-1.7,1.43-1.13,2.14-2.93,2.14-5.4,0-1.43-.39-2.59-1.18-3.49-.78-.9-1.81-1.64-3.09-2.23-1.27-.59-2.72-1.11-4.35-1.55-1.62-.45-3.29-.94-5-1.49s-3.37-1.22-5-2.02-3.07-1.85-4.35-3.15c-1.27-1.3-2.3-2.93-3.09-4.87-.78-1.95-1.18-4.35-1.18-7.2,0-2.3.46-4.54,1.39-6.72s2.28-4.13,4.07-5.84c1.79-1.71,3.99-3.07,6.59-4.1,2.6-1.02,5.59-1.53,8.95-1.53,1.88,0,3.7.15,5.48.44,1.78.29,3.46.73,5.06,1.3,1.6.57,3.09,1.26,4.47,2.06s2.62,1.7,3.72,2.71l-3.53,6.59Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M729.53,102.06v10.92h-25.12v14.28h19.24v10.5h-19.24v14.57h25.12v10.92h-39.4v-61.19h39.4Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M761.91,102.14c.42.06.8.16,1.13.31.34.15.66.37.97.65.31.28.66.66,1.05,1.13l29.06,36.75c-.11-1.18-.2-2.32-.25-3.42-.06-1.11-.08-2.15-.08-3.13v-32.38h12.52v61.19h-7.39c-1.09,0-2.01-.17-2.75-.52-.74-.35-1.48-.96-2.21-1.83l-28.85-36.46c.08,1.06.15,2.11.21,3.13.06,1.02.08,1.98.08,2.88v32.8h-12.52v-61.19h7.48c.62,0,1.13.03,1.55.08Z"
        />
        <path
          style={{
            fill: "var(--litesite-callout-text, #221f20)",
          }}
          d="M888.29,132.64c0,4.42-.76,8.51-2.29,12.24-1.53,3.74-3.67,6.97-6.45,9.7-2.77,2.73-6.11,4.86-10.02,6.38-3.91,1.53-8.24,2.29-13,2.29h-23.77v-61.19h23.77c4.76,0,9.09.77,13,2.31,3.91,1.54,7.25,3.67,10.02,6.38,2.77,2.72,4.92,5.94,6.45,9.68,1.53,3.74,2.29,7.81,2.29,12.2ZM873.76,132.64c0-3.02-.39-5.75-1.18-8.19-.78-2.44-1.91-4.5-3.38-6.19-1.47-1.69-3.27-3-5.4-3.91-2.13-.91-4.55-1.36-7.27-1.36h-9.49v39.35h9.49c2.71,0,5.14-.46,7.27-1.37,2.13-.91,3.93-2.21,5.4-3.91,1.47-1.69,2.6-3.76,3.38-6.2.78-2.44,1.18-5.18,1.18-8.23Z"
        />
      </g>
    </svg>
  );
}
