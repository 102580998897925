import { Outlet, ScrollRestoration } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import "react-international-phone/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "reset-css";

import classNames from "classnames";
import { type CSSProperties, Fragment } from "react";
import { litesiteThemeObject } from "schemas";
import { flexStyles, heightStyles, textStyles, transitionStyles } from "ui";
import { Toast } from "./ui/Toast";

import { useBreakpoint } from "../hooks";
import { useCurrentUser } from "../store";
import { DesktopNav, MobileNav } from "./nav";
import { Masquerade } from "./ui";

export function App() {
  const desktop = useBreakpoint("desktop");
  const user = useCurrentUser();

  const Nav = desktop ? DesktopNav : MobileNav;
  const Wrapper = user ? Nav : Fragment;

  return (
    <div
      id="inner-root"
      style={litesiteThemeObject() as CSSProperties}
      className={classNames(
        heightStyles.minHeightFull,
        flexStyles.vert,
        textStyles.fontPoppins,
        transitionStyles.all200,
      )}
    >
      <ScrollRestoration
        getKey={(location, _matches) => {
          const includes = ["/content", "/contacts"].includes(location.pathname);
          const matches = location.pathname.match(/^\/campaigns\/([0-9]+)\/content/);
          return includes || matches
            ? // restores scroll
              matches?.[0] || location.pathname
            : // everything else by location like the browser
              location.key;
        }}
      />
      <Toast />
      <Masquerade />
      <Wrapper>
        <Outlet />
      </Wrapper>

      <div id="litesite-drawer" />
      <div id="litesite-confirm" />
    </div>
  );
}
