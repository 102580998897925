import classNames from "classnames";
import { Outlet, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  BigLink,
  EmptyElements,
  GetYours,
  Loader,
  type PopoverLinkItem,
  ThemedWrapper,
  flexStyles,
  paddingStyles,
  useShareHandler,
  widthStyles,
} from "ui";
import { useUrlWithBack } from "../../hooks";
import { useCurrentBusiness, useHomePage, usePageElements, useTheme } from "../../store";
import { BlocksEditor } from "../blocks";
import { Theme } from "../ui";
import { EditBusinessDescription, EditBusinessLogo, EditBusinessName } from "../ui/InlineEditors";

export function Home() {
  const business = useCurrentBusiness();
  const navigate = useNavigate();
  const homePage = useHomePage();
  const elements = usePageElements(homePage?.id);
  const theme = useTheme(homePage?.themeId);
  const { onClick: shareLiteSite } = useShareHandler(business.url, toast);

  const upgradeUrl = useUrlWithBack("/billing/discovered/branding");

  if (!homePage || !theme) {
    return <Loader />;
  }

  const linkLinks: PopoverLinkItem[] = [
    {
      label: "Preview",
      onClick: async () => {
        window.open(business.url);
      },
      iconType: "Show",
    },
    {
      label: "Edit Link",
      onClick: async () => {
        navigate("/business/link");
      },
      iconType: "Edit",
    },
    {
      label: "Share",
      onClick: shareLiteSite,
      iconType: "Share",
    },
  ];
  const empty = elements?.length === 0 && (
    <EmptyElements
      addElement={async () => {
        navigate("blocks/new/1");
      }}
    />
  );

  return (
    <ThemedWrapper className={classNames(flexStyles.grow, flexStyles.vert, flexStyles.alignCenter)} theme={theme}>
      <BigLink
        className={widthStyles.full}
        links={linkLinks}
        relativeUrl={business.relativeUrl}
        host={import.meta.env.REACT_APP_PUBLIC_DOMAIN}
      />
      <Theme theme={theme} />
      <div
        className={classNames(
          flexStyles.vert100,
          widthStyles.maxWidth4225,
          widthStyles.full,
          paddingStyles.p125,
          paddingStyles.pb200,
        )}
      >
        <EditBusinessLogo />
        <EditBusinessName />
        <EditBusinessDescription />
        <BlocksEditor elements={elements || []} emptyBlock={empty} destination={{ type: "Page", entity: homePage }} />
      </div>
      <Outlet />
      <GetYours
        onHide={() => {
          navigate(upgradeUrl);
        }}
        onClick={() => {}}
        disabled
        business={business}
      />
    </ThemedWrapper>
  );
}
