import classNames from "classnames";
import type { CSSProperties, FC, PropsWithChildren } from "react";
import { type PersistedTextBlock, textColorCSS } from "schemas";
import { textStyles, widthStyles } from "../styles";
import { Markdown } from "../typography";

interface Props {
  block: PersistedTextBlock;
  TextWrapper?: FC<PropsWithChildren>;
}
export function TextBlockCard({ block, TextWrapper }: Props) {
  const textColorStyle = textColorCSS(block.textColor) as CSSProperties;
  return (
    <Markdown
      style={textColorStyle}
      fontFamily={block.fontFamily}
      className={classNames(textStyles.fontTheme, widthStyles.full, {
        [textStyles.center]: block.textAlignment === "Center",
      })}
      TextWrapper={TextWrapper}
    >
      {block.text}
    </Markdown>
  );
}
