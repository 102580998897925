import { FontFamilyValues } from "schemas";
import type { PropsWithName } from "../../types";
import { Select } from "../Select";

interface Props extends PropsWithName {}
export function ToolbarFontFamilySelect({ name }: Props) {
  const options = FontFamilyValues.map((value) => ({ label: value, value }));
  const optionsWithEmpty = [{ label: "Select font", value: null }, ...options];

  return <Select placeholder="Select&nbsp;font" raw name={name} options={optionsWithEmpty} />;
}
