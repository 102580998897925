import classNames from "classnames";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import type { CampaignStats } from "schemas";
import { Card, HeaderMain, Loader, SmallTextMain, TextMain, flexStyles, paddingStyles, textStyles } from "ui";
import { fetchCampaignStats, useCampaign } from "../../store";
import { Button, Link, Overlay } from "../ui";
import { CampaignStat } from "./CampaignStat";

function percentage(number: number) {
  return `${Math.round(number * 10000) / 100}%`;
}

export function Stats() {
  const [stats, setStats] = useState<CampaignStats | undefined>(undefined);
  const { campaign } = useCampaign();

  useEffect(() => {
    if (campaign && !stats) {
      fetchCampaignStats(campaign.id).then((stats) => setStats(stats));
    }
  }, [campaign, stats]);

  if (!campaign || !stats) {
    return <Loader />;
  }

  const refreshStats = () => {
    setStats(undefined);
  };

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap200)}
      header="Campaign Stats"
      title={campaign.name}
      subtitle={
        <>
          <TextMain>{campaign.subject}</TextMain>
          {campaign.sentAt && (
            <SmallTextMain className={textStyles.neutral400}>
              Sent: {DateTime.fromISO(campaign.sentAt).toRelative()}
            </SmallTextMain>
          )}
          <Link href={campaign.url} target="_blank">
            Preview
          </Link>
        </>
      }
      backTo={{ to: "../..", label: "Campaigns List" }}
    >
      <div className={flexStyles.vert050}>
        <div className={classNames(flexStyles.horizApart, flexStyles.alignCenter)}>
          <SmallTextMain>Performance</SmallTextMain>
          <Button size="medium" buttonType="tertiary" onClick={refreshStats}>
            Refresh
          </Button>
        </div>
        <div className={flexStyles.horiz050}>
          <Card className={classNames(paddingStyles.p125, flexStyles.grow)}>
            <TextMain>Unique Open Rate</TextMain>
            <HeaderMain>{percentage(stats.openedCount / stats.totalCount)}</HeaderMain>
          </Card>
          <Card className={classNames(paddingStyles.p125, flexStyles.grow)}>
            <TextMain>Unique Click Rate</TextMain>
            <HeaderMain>{percentage(stats.clickedCount / stats.totalCount)}</HeaderMain>
          </Card>
        </div>
        <div className={flexStyles.horiz050}>
          <Card className={classNames(paddingStyles.p125, flexStyles.grow)}>
            <TextMain>Total Open Rate</TextMain>
            <HeaderMain>{percentage(stats.allOpenedCount / stats.totalCount)}</HeaderMain>
          </Card>
          <Card className={classNames(paddingStyles.p125, flexStyles.grow)}>
            <TextMain>Total Click Rate</TextMain>
            <HeaderMain>{percentage(stats.allClickedCount / stats.totalCount)}</HeaderMain>
          </Card>
        </div>
      </div>
      <div className={flexStyles.vert050}>
        <SmallTextMain>General Stats</SmallTextMain>
        <CampaignStat statType="processed" label="Sent" count={stats.processedCount} />
        <CampaignStat statType="opened" label="Unique Opened" count={stats.openedCount} />
        <CampaignStat label="Total Opened" count={stats.allOpenedCount} />
        <CampaignStat statType="clicked" label="Unique Clicked" count={stats.clickedCount} />
        <CampaignStat label="Total Clicked" count={stats.allClickedCount} />
        <CampaignStat statType="markedAsSpam" label="Spam" count={stats.spamCount} />
        <CampaignStat statType="unsubscribed" label="Unsubscribes" count={stats.unsubscribeCount} />
      </div>
      <div className={flexStyles.vert050}>
        <SmallTextMain>Delivery Stats</SmallTextMain>
        <CampaignStat statType="delivered" label="Successful deliveries" count={stats.deliveredCount} />
        <CampaignStat statType="bounced" label="Bounces" count={stats.bouncedCount} />
      </div>
    </Overlay>
  );
}
