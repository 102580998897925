import { orderBy } from "es-toolkit";
import { useContactGroups } from "./useContactGroups";

export function useContactGroupOptions() {
  const { loading, contactGroups } = useContactGroups(["AllContacts", "Unconfirmed", "Suppressed"]);
  if (contactGroups.length === 0 || loading) {
    return [];
  }
  return [
    { label: "None", value: undefined },
    ...orderBy(contactGroups, ["name"], ["asc"]).map((contactGroup) => ({
      label: contactGroup.name,
      value: contactGroup.id.toString(),
    })),
  ];
}
