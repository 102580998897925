import classNames from "classnames";
import type { PropsWithChildren } from "react";
import type { ThemePayload } from "schemas";
import { bgStyles, textStyles } from "../styles";
import type { PropsWithClassName } from "../types";
import { RawThemedWrapper } from "./RawThemedWrapper";

interface Props extends PropsWithClassName, PropsWithChildren {
  theme: ThemePayload;
}
export function ThemedWrapper({ theme, children, className }: Props) {
  return (
    <RawThemedWrapper
      theme={theme}
      className={classNames(bgStyles.businessBackground, textStyles.fontTheme, className)}
    >
      {children}
    </RawThemedWrapper>
  );
}
