import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { textStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props extends PropsWithClassName, PropsWithChildren {}

export function Span({ children, className }: Props) {
  return <span className={classNames(textStyles.lsColor, className)}>{children}</span>;
}
