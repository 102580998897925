import classNames from "classnames";
import { DateTime } from "luxon";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useFormContext, useWatch } from "react-hook-form";
import { bgStyles, flexStyles, textStyles } from "../styles";
import type { PropsWithName, PropsWithOptionalLabel } from "../types";
import { Error } from "./Error";
import { Label } from "./Label";
import formStyles from "./forms.module.css";

interface Props extends PropsWithName, PropsWithOptionalLabel {}

export function DateInput({ name, label }: Props) {
  const { register, setValue } = useFormContext();

  const value = useWatch({ name });

  useEffect(() => {
    register(name);
  }, [register, name]);

  const onChange = (newValue: Date | null) => {
    newValue
      ? setValue(name, DateTime.fromJSDate(newValue).toISO(), {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        })
      : setValue(name, null, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  };

  const filterPassedTime = (time: Date) => {
    return DateTime.fromJSDate(time).diffNow(["minutes"]).minutes > 0;
  };

  return (
    <div className={classNames(flexStyles.vert025)}>
      <Label name={name}>{label}</Label>
      <DatePicker
        placeholderText="Pick date and time"
        minDate={DateTime.now().toJSDate()}
        selected={value && DateTime.fromISO(value).toJSDate()}
        onChange={onChange}
        dateFormat="MMMM d, yyyy h:mm aa"
        showTimeSelect
        filterTime={filterPassedTime}
        className={classNames(textStyles.fontPoppins, bgStyles.neutral0, formStyles.input)}
      />
      <Error name={name} />
    </div>
  );
}
