import { useFormContext, useWatch } from "react-hook-form";
import type { IconType } from "../../Icon";

import type { TextAlignment } from "schemas";
import { IconButton } from "../../IconButton";
import type { PropsWithName } from "../../types";
import styles from "./Toolbar.module.css";

const NextValue: Record<TextAlignment, TextAlignment> = {
  Center: "Default",
  Default: "Center",
};

interface Props extends PropsWithName {}
export function ToolbarTextAlign({ name }: Props) {
  const { setValue } = useFormContext();
  const textAlign = useWatch({ name }) as TextAlignment;
  const iconType: IconType = textAlign === "Center" ? "TextAlignCenter" : "TextAlignLeft";
  const onClick = () => {
    setValue(name, NextValue[textAlign], { shouldTouch: true, shouldDirty: true, shouldValidate: true });
  };
  return <IconButton className={styles.button} square onClick={onClick} iconType={iconType} />;
}
