"use client";

import classNames from "classnames";
import type { CSSProperties, PropsWithChildren } from "react";
import { type ThemePayload, businessThemeObject } from "schemas";
import { bgStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props extends PropsWithClassName, PropsWithChildren {
  theme: ThemePayload;
  onClick?: (theme: ThemePayload) => void;
}
export function ClickableThemedWrapper({ theme, children, className, onClick }: Props) {
  return (
    <div
      onClick={() => onClick?.(theme)}
      className={classNames(bgStyles.businessBackground, className)}
      style={businessThemeObject(theme) as CSSProperties}
    >
      {children}
    </div>
  );
}
