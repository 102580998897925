import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { ThemeSolids } from "schemas";
import { Link } from "../Link";
import { borderStyles, flexStyles, pointerStyles, squareStyles, textStyles } from "../styles";
import type { PropsWithName, PropsWithStyle } from "../types";
import { SmallTextInput } from "./TextInput/SmallTextInput";

interface Props extends PropsWithName, PropsWithStyle {}
export function ColorInput({ name, style }: Props) {
  const { setValue, trigger } = useFormContext();
  return (
    <div style={style} className={classNames(flexStyles.vert050)}>
      <div className={classNames(flexStyles.horiz050, flexStyles.justifyEvenly, flexStyles.wrap)}>
        {ThemeSolids.map(({ color }) => (
          <button
            type="button"
            key={color}
            style={{ backgroundColor: color }}
            className={classNames(
              borderStyles.none,
              squareStyles.square125,
              borderStyles.radius025,
              pointerStyles.cursor,
            )}
            onClick={() => {
              setValue(name, color, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
            }}
          />
        ))}
      </div>
      <SmallTextInput className={textStyles.primary} label="Hex code" name={name} onChange={() => trigger(name)} />
      <Link
        className={classNames(textStyles.primary)}
        size="small"
        onClick={() => setValue(name, null, { shouldDirty: true, shouldTouch: true, shouldValidate: true })}
      >
        Reset to theme default
      </Link>
    </div>
  );
}
