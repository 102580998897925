import type { SVGProps } from "react";

const SvgChevronSmallUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m10.778 8.341 2.975 3.152a.9.9 0 0 1 .228.452.933.933 0 0 1-.048.51.874.874 0 0 1-.307.396.801.801 0 0 1-.462.15h-6.33a.8.8 0 0 1-.464-.15.873.873 0 0 1-.307-.396.931.931 0 0 1-.047-.51.9.9 0 0 1 .229-.452L9.22 8.341c.207-.218.487-.341.779-.341.292 0 .572.123.779.341Z"
      fill="#465357"
    />
  </svg>
);
export default SvgChevronSmallUp;
