import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { textStyles } from "../styles";
import type { PropsWithClassName, PropsWithStyle } from "../types";

interface Props extends PropsWithChildren, PropsWithClassName, PropsWithStyle {}
export function Description({ children, className, style }: Props) {
  return (
    <div style={style} className={classNames(textStyles.size075, textStyles.lsColor, textStyles.weight400, className)}>
      {children}
    </div>
  );
}

export function DescriptionAccent({ children, className }: Props) {
  return <Description className={classNames(textStyles.weight600, className)}>{children}</Description>;
}

export function DescriptionItalic({ children, className }: Props) {
  return <Description className={classNames(textStyles.italic, className)}>{children}</Description>;
}
