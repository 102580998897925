import { DateTime } from "luxon";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router";
import { CampaignSchema, type PersistedCampaign } from "schemas";
import { DateInput, Drawer, FormProvider, FormTag, Loader, TextMain, bgStyles } from "ui";
import { sendCampaign, updateCampaign, useCampaign } from "../../store";
import { Submit } from "../ui";

function ScheduleSubmit() {
  const scheduledAt = useWatch({ name: "scheduledAt" });
  const label = scheduledAt
    ? `Schedule send at ${DateTime.fromISO(scheduledAt).toFormat("dd/LL/yyyy HH:mm")}`
    : "Schedule send";
  return <Submit cancelTo=".." disabled={!scheduledAt} label={label} />;
}

export function ScheduleDrawer() {
  const navigate = useNavigate();

  const { campaign } = useCampaign();

  if (!campaign) {
    return <Loader />;
  }

  const onClose = () => {
    navigate("..", { relative: "path", preventScrollReset: true });
  };

  const onSubmit = async (payload: PersistedCampaign) => {
    await updateCampaign(campaign.id, payload);
    await sendCampaign(campaign.id);
    navigate("../../sent", { relative: "path" });
  };

  return (
    <Drawer className={bgStyles.neutral100} onClose={onClose} title="Schedule send" open>
      <FormProvider defaultValues={campaign} schema={CampaignSchema}>
        <FormTag<PersistedCampaign> onSubmit={onSubmit}>
          <TextMain>Pick a future date and time to schedule the send. Do not worry you can reschedule later.</TextMain>
          <DateInput name="scheduledAt" />
          <ScheduleSubmit />
        </FormTag>
      </FormProvider>
    </Drawer>
  );
}
