import { toast } from "react-toastify";

const { REACT_APP_BE_DOMAIN } = import.meta.env;
const NON_NOTIFYING_MESSAGES = ["EXCEEDED_FREE_CONTACTS", "link is not available", "Snappy Error"];

interface Args {
  url: string;
  method?: "GET" | "DELETE" | "POST" | "PATCH" | "PUT";
  body?: string;
  signal?: AbortSignal;
}
export async function apiRequest<R extends object>({ url, method = "GET", body, signal }: Args) {
  const requestUrl = `${REACT_APP_BE_DOMAIN}/${url}`;
  const request = await fetch(requestUrl, {
    method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body,
    signal,
  });

  if (!request.ok) {
    const error = await request.json();
    if (request.status === 403) {
      if (window.location.pathname !== "/login") {
        window.location.pathname = "/login";
      }
    }

    if (request.status === 422) {
      const { message } = error as { message?: string };
      if (message && !NON_NOTIFYING_MESSAGES.includes(message)) {
        toast.error(message);
      }
    }

    throw new Error(error.message);
  }

  const result = (await request.json()) as R;
  return result;
}
