"use client";

import type { ReactNode } from "react";

import classNames from "classnames";
import { type Path, type PathValue, useFormContext } from "react-hook-form";
import { prefixedName } from "../../helpers";
import { flexStyles, heightStyles } from "../styles";
import type { PropsWithOptionalLabel, PropsWithOptionalPrefix } from "../types";
import { Error } from "./Error";
import { Label, LabelWithCount } from "./Label";
import { type RichEditorType, Wysiwyg } from "./Wysiwyg";

interface Props<T> extends PropsWithOptionalLabel, PropsWithOptionalPrefix {
  name: Path<T>;
  type: RichEditorType;
  placeholder?: string;
  maxCharacterCount?: number;
  onSubmit?: (payload: T) => Promise<void>;
  extra?: ReactNode;
  autoFocus?: boolean;
  fontSelectable?: boolean;
  textAlignmentSelectable?: boolean;
}

export function VisualTextArea<T extends object>({
  name,
  prefix,
  label,
  type,
  placeholder,
  onSubmit,
  extra,
  autoFocus = false,
  maxCharacterCount = 0,
  fontSelectable = false,
  textAlignmentSelectable = false,
}: Props<T>) {
  const { setValue, handleSubmit } = useFormContext<T>();

  const hasCharacterCount = maxCharacterCount > 0;
  return (
    <div className={classNames(flexStyles.vert025, heightStyles.min1500, flexStyles.grow)}>
      {hasCharacterCount ? (
        <LabelWithCount name={name} maxCharacterCount={maxCharacterCount}>
          {label}
        </LabelWithCount>
      ) : (
        label && (
          <Label name={name} extra={extra}>
            {label}
          </Label>
        )
      )}
      <Wysiwyg
        onSubmit={onSubmit ? handleSubmit(onSubmit) : undefined}
        type={type}
        autoFocus={autoFocus}
        name={name}
        prefix={prefix}
        setValue={(newValue: string) =>
          setValue(prefixedName(name, prefix) as Path<T>, newValue as PathValue<T, Path<T>>, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          })
        }
        placeholder={placeholder}
        fontSelectable={fontSelectable}
        textAlignmentSelectable={textAlignmentSelectable}
      />
      <Error name={name} />
    </div>
  );
}
