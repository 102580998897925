"use client";

import { useCopyToClipboard } from "react-use";

type Hook = {
  onClick: () => Promise<void>;
};
export function useShareHandler(url: string, onCopy?: (text: string) => void): Hook {
  const [, copyToClipboard] = useCopyToClipboard();
  const onClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url,
        });
      } catch (error) {
        const { name: errorName } = error as { name?: string };
        if (!["AbortError", "InvalidStateError"].includes(errorName || "")) {
          throw error;
        }
      }
    } else {
      copyToClipboard(url);
      onCopy?.("Link copied to your clipboard");
    }
  };

  return { onClick };
}
