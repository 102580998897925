import { useEffect, useState } from "react";
import { useLiteSiteStore } from "../store";
import type { HookLoading } from "../types";
import { getCampaignScreenshot } from "./getCampaignScreenshot";

export function useCampaignScreenshot(id: string): HookLoading<{ url: string | undefined }> {
  const [loading, setLoading] = useState<boolean>(true);
  const url = useLiteSiteStore((state) => state.campaignScreenshots[id]);

  useEffect(() => {
    if (id && loading) {
      getCampaignScreenshot(id).finally(() => setLoading(false));
    }
  }, [id, loading]);

  return { loading, url };
}
