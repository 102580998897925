import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { textStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props extends PropsWithClassName, PropsWithChildren {}

export function Strong({ children, className }: Props) {
  return <strong className={classNames(textStyles.weight600, className)}>{children}</strong>;
}
