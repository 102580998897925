import classNames from "classnames";
import { DateTime } from "luxon";
import {
  Icon,
  Loader,
  SubheaderMain,
  TextMain,
  borderStyles,
  flexStyles,
  heightStyles,
  iconStyles,
  imageStyles,
  paddingStyles,
  widthStyles,
} from "ui";
import { useCampaign, useCampaignScreenshot } from "../../store";
import { Button } from "../ui";

export function Sent() {
  const { campaign } = useCampaign();
  const { url } = useCampaignScreenshot(campaign?.id || "");

  if (!campaign) {
    return <Loader />;
  }

  const { scheduledAt } = campaign;

  const header = scheduledAt ? "Campaign scheduled successfully!" : "Campaign sent successfully!";
  const mainSuffix = scheduledAt
    ? `scheduled to send ${DateTime.fromISO(scheduledAt).toRelative()}.`
    : "sent successfully.";

  return (
    <div
      className={classNames(
        iconStyles.successMedium,
        flexStyles.grow,
        flexStyles.vert100,
        flexStyles.justifyBetween,
        flexStyles.alignCenter,
        paddingStyles.px125,
        paddingStyles.pb200,
        paddingStyles.pt600,
      )}
    >
      <div className={classNames(flexStyles.vert050, flexStyles.alignCenter)}>
        <Icon iconType="Success" />
        <div className={classNames(flexStyles.vert125, flexStyles.alignCenter)}>
          <SubheaderMain>{header}</SubheaderMain>
          {url && (
            <div className={classNames(borderStyles.fadeOutBottom, widthStyles.width1000, heightStyles.height2000)}>
              <img
                className={classNames(
                  imageStyles.fitCover,
                  widthStyles.full,
                  heightStyles.full,
                  imageStyles.positionTop,
                )}
                src={url}
                aria-label="Campaign screenshot"
              />
            </div>
          )}
          <TextMain>
            Campaign &quot;{campaign?.subject}&quot; {mainSuffix}
          </TextMain>
        </div>
      </div>
      <Button className={classNames(widthStyles.maxContent, widthStyles.full)} buttonType="secondary" to="/campaigns">
        Done
      </Button>
    </div>
  );
}
