import type { SVGProps } from "react";
const SvgAuthStarBottom = (props: SVGProps<SVGSVGElement>) => (
  <svg width={35} height={27} viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5 21.46c1.977-.726 4.316-.814 6.38-1.265 1.195-.261 2.338-.605 3.507-.964 1.467-.45 5.806-1.996 4.348-1.516-1.668.55-3.532 1.16-5.271 1.395-1.422.192-2.806.697-4.204.848-1.3.14-2.408.873-3.667 1.04-.416.054-.808.075-1.19.256-.411.195.839-.356 1.225-.594 2.15-1.327 4.45-2.423 6.399-4.023a73.563 73.563 0 0 1 2.817-2.215c.615-.458 1.735-.944 2.15-1.59.304-.476-1.503-2.64-1.783-3.232-.868-1.831-2.315-3.55-3.433-5.24-.402-.608-.896-1.768-1.43-2.254-.022-.02 1.271.636 1.638.983 1.794 1.696 3.385 3.644 5.265 5.28 1.068.928 2.873 2.04 4.308 1.49 1.164-.446 2.389-.654 3.568-1.189 1.878-.852 3.723-1.764 5.597-2.652l4.103-1.944c.546-.259-.433.633-.552.778a25.658 25.658 0 0 1-3.867 3.828c-1.306 1.035-2.878 1.867-3.97 3.138-.715.831-1.7 1.972-2.65 2.511-1.978 1.124.887 3.58 1.527 4.93.536 1.131 1.265 2.14 1.81 3.288.223.471 1.265 2.452.918 2.203-1.905-1.363-3.669-3.068-5.541-4.535-.683-.535-1.214-1.337-1.955-1.711-.436-.22-1.142-.893-1.544-.902"
      stroke="#F5A343"
      strokeWidth={3}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgAuthStarBottom;
