import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import { getCampaignElements } from "./getCampaignElements";
import { getCampaignScreenshot } from "./getCampaignScreenshot";

export async function reorderCampaignElements(
  campaignId: string,
  campaignElementId: string,
  ogPosition: number,
  position: number,
) {
  const lower = Math.min(ogPosition, position);
  const upper = Math.max(ogPosition, position);
  const direction = ogPosition > position ? "up" : "down";

  useLiteSiteStore.setState(({ campaignElements }) => {
    const elements = campaignElements.get(campaignId);
    if (!elements) {
      return {};
    }
    const newElements = elements.map((element) => {
      if (
        (element.position === lower && direction === "up") ||
        (element.position === upper && direction === "down") ||
        (element.position > lower && element.position < upper)
      ) {
        const newPosition = direction === "up" ? element.position + 1 : element.position - 1;
        return { ...element, position: newPosition };
      }
      if (element.position === ogPosition) {
        return { ...element, position };
      }
      return element;
    });

    return { campaignElements: campaignElements.set(campaignId, newElements) };
  });

  await apiRequest({
    url: `campaignElements/${campaignId}/elements/${campaignElementId}/reorder/${position.toString()}`,
    method: "PUT",
  });
  getCampaignScreenshot(campaignId);
  await getCampaignElements(campaignId);
}
