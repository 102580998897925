import classNames from "classnames";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import type { CampaignStatus, PersistedCampaign } from "schemas";
import {
  Description,
  DotMenu,
  type DotMenuLink,
  SmallTextMain,
  TextAccent,
  flexStyles,
  paddingStyles,
  textStyles,
  useConfirm,
} from "ui";
import { cancelScheduledSend, cloneCampaign, deleteCampaign } from "../../store";
import { SuperCard } from "../ui";
import { CampaignScreenshot } from "./CampaignScreenshot";
import { CampaignStatusLabel } from "./CampaignStatusLabel";

interface Props {
  campaign: PersistedCampaign;
}

export function CampaignCard({ campaign }: Props) {
  const navigate = useNavigate();
  const { confirm, Confirm } = useConfirm();
  const statsStatuses: CampaignStatus[] = ["Sent", "Sending", "Scheduled", "Cancelled"];
  const to = statsStatuses.includes(campaign.status) ? `${campaign.id}/stats` : `${campaign.id}/content`;

  const links: DotMenuLink[] = [
    {
      label: "Rename Campaign",
      iconType: "Edit",
      onClick: () => {
        navigate(`${campaign.id}/edit`);
      },
    },
    {
      label: "Clone Campaign",
      iconType: "Copy",
      onClick: async () => {
        const clone = await cloneCampaign(campaign.id);

        navigate(`/campaigns/${clone.id}/edit`);
      },
    },
    {
      label: "Delete Campaign",
      iconType: "Delete",
      onClick: () => {
        confirm({
          text: `"${campaign.name}" campaign will be permanently deleted. This action cannot be undone. Are you sure you want to delete this campaign?`,
          animationType: "Thrash",
          label: "Delete Campaign",
          onConfirm: async () => deleteCampaign(campaign.id),
        });
      },
    },
  ];

  if (campaign.status === "Scheduled") {
    links.push({
      label: "Cancel Scheduled Send",
      iconType: "X",
      onClick: () => {
        confirm({
          text: `"${campaign.name}" campaign will go batch to draft and you can send it again`,
          label: "Cancel scheduled send",
          onConfirm: async () => {
            await cancelScheduledSend(campaign.id);
          },
        });
      },
    });
  }

  return (
    <SuperCard
      to={to}
      title={
        <div className={classNames(flexStyles.horiz050, flexStyles.wrap)}>
          <TextAccent>{campaign.name}</TextAccent>
          <TextAccent>•</TextAccent>
          <CampaignStatusLabel campaign={campaign} />
        </div>
      }
      right={<DotMenu links={links} />}
    >
      <div className={flexStyles.horiz}>
        <CampaignScreenshot campaign={campaign} />
        <div
          dir="auto"
          className={classNames(flexStyles.grow, flexStyles.vert050, paddingStyles.p125, textStyles.primary)}
        >
          <Confirm />
          <SmallTextMain>{campaign.subject}</SmallTextMain>
          <Description className={textStyles.neutral400}>
            {campaign.sentAt ? (
              <>Sent: {DateTime.fromISO(campaign.sentAt).toRelative()}</>
            ) : campaign.scheduledAt && campaign.status === "Scheduled" ? (
              <>Scheduled: {DateTime.fromISO(campaign.scheduledAt).toRelative()}</>
            ) : (
              <>Created: {DateTime.fromISO(campaign.insertedAt).toRelative()}</>
            )}
          </Description>
        </div>
      </div>
    </SuperCard>
  );
}
