import { type Asserts, array, boolean, object, string } from "yup";
import type { PersistedFormAnswer } from "./FormAnswer";
import { type FormQuestionPayload, FormQuestionSchema } from "./FormQuestion";
import { LinkValidation } from "./Link";

export const FormSchema = object({
  name: string().required("Form name is required"),
  description: string().nullable(),
  handle: LinkValidation,
  destinationContactGroup: string().nullable(),
  subscribesContacts: boolean(),
  hideName: boolean(),
  questions: array().of(FormQuestionSchema).required(),
});
export type FormPayload = Asserts<typeof FormSchema>;

export type PersistedForm = FormPayload & {
  id: string;
  businessId: string;
  questions: FormQuestionPayload[];
  url: string;
  publicUuid: string;
  usedInAppointments: string[];
  formAnswers: PersistedFormAnswer[];
  deletable: boolean;
  hideName: boolean;
};

export type PublicForm = Pick<
  PersistedForm,
  "name" | "description" | "subscribesContacts" | "hideName" | "questions" | "publicUuid" | "id"
>;

export const EmptyForm: FormPayload = {
  name: "",
  description: "",
  questions: [],
};
