import type { Path } from "react-hook-form";
import { type PropsWithOptionalPrefix, VisualTextArea } from "ui";

interface Props<T> extends PropsWithOptionalPrefix {
  onSubmit: (payload: T) => Promise<void>;
}
export function TextBlockEditor<T extends object>({ onSubmit, prefix }: Props<T>) {
  return (
    <VisualTextArea<T>
      onSubmit={onSubmit}
      type="inline"
      name={"text" as Path<T>}
      prefix={prefix}
      placeholder="Add a text block"
      fontSelectable
      textAlignmentSelectable
    />
  );
}
