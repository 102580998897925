import { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import type { CampaignStatType } from "schemas";
import { Description, Loader } from "ui";
import { fetchCampaignStatEmails, useCampaign } from "../../store";

interface Props {
  width?: number;
  statType: CampaignStatType;
}
export function CampaignStatEmails({ width, statType }: Props) {
  const { campaign } = useCampaign();
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (campaign?.id) {
      fetchCampaignStatEmails(campaign.id, statType)
        .then(setEmails)
        .then(() => setLoading(false));
    }
  }, [campaign?.id, statType]);

  if (loading) {
    return <Loader fitted />;
  }

  return (
    <List height={150} itemCount={emails.length} itemSize={18} width={width || 300}>
      {({ index, style }) => (
        <Description style={style} key={emails[index]}>
          {emails[index]}
        </Description>
      )}
    </List>
  );
}
