import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { textStyles } from "../styles";
import type { PropsWithClassName } from "../types";

interface Props extends PropsWithClassName, PropsWithChildren {}

export function SmallTextMain({ children, className }: Props) {
  return <div className={classNames(textStyles.size087, textStyles.lsColor, className)}>{children}</div>;
}

export function SmallTextAccent({ children, className }: Props) {
  return <SmallTextMain className={classNames(textStyles.weight600, className)}>{children}</SmallTextMain>;
}
