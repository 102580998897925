import { SenderDetailsForm } from "../components/business";
import {
  ChooseSubject,
  Content,
  Design,
  Edit,
  EditBusinessAddress,
  Generate,
  Home,
  New,
  Preview,
  Recipients,
  ScheduleDrawer,
  Sent,
  Stats,
  Subject,
  Summary,
} from "../components/campaigns";
import { OutletBlockRoutes, StandaloneBlockRoutes } from "./Blocks";
import { PrivateOutlet } from "./PrivateOutlet";
import { OutletThemeRoutes } from "./Theme";

export const CampaignsRoutes = {
  path: "/campaigns",
  Component: PrivateOutlet,
  children: [
    {
      path: "",
      element: <Home filter="All" />,
    },
    {
      path: "draft",
      element: <Home filter="Draft" />,
    },
    {
      path: "sent",
      element: <Home filter="Sent" />,
    },
    {
      path: "scheduled",
      element: <Home filter="Scheduled" />,
    },
    { path: "new", Component: New },

    {
      path: ":campaignId",
      children: [
        { path: "edit", Component: Edit },
        {
          path: "design",
          Component: Design,
          children: OutletThemeRoutes,
        },
        {
          path: "content",
          Component: Content,
          children: [OutletBlockRoutes, { path: "editAddress", Component: EditBusinessAddress }],
        },
        { path: "content", children: [StandaloneBlockRoutes] },
        { path: "recipients", Component: Recipients },
        { path: "subject", Component: Subject },
        {
          path: "summary",
          children: [
            {
              path: "",
              Component: Summary,
              children: [
                {
                  path: "schedule",
                  Component: ScheduleDrawer,
                },
              ],
            },
            {
              path: "sender",
              Component: SenderDetailsForm,
            },
          ],
        },
        { path: "sent", Component: Sent },
        { path: "stats", Component: Stats },
      ],
    },

    {
      path: "generate",
      children: [
        { path: ":promptId?", Component: Generate },
        { path: ":promptId/subjects", Component: ChooseSubject },
        { path: ":promptId/preview/:campaignId", Component: Preview },
      ],
    },
  ],
};
