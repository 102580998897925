import classNames from "classnames";
import type { CSSProperties } from "react";
import { useWatch } from "react-hook-form";
import { textColorCSS } from "schemas";
import { Popover } from "../../Popover";
import {
  bgStyles,
  borderStyles,
  displayStyles,
  flexStyles,
  paddingStyles,
  positionStyles,
  squareStyles,
} from "../../styles";
import type { PropsWithName } from "../../types";
import { ColorInput } from "../ColorInput";

interface Props extends PropsWithName {}
export function ColorButton({ name }: Props) {
  const textColor = useWatch({ name });
  const style = textColorCSS(textColor) as CSSProperties;
  return (
    <div style={style} className={classNames(positionStyles.relative, flexStyles.shrink0, paddingStyles.px050)}>
      <Popover
        className={classNames(
          displayStyles.block,
          squareStyles.square125,
          borderStyles.none,
          borderStyles.radiusRound,
          bgStyles.textColor,
          flexStyles.shrink0,
        )}
        popoverId="color-picker"
      >
        <ColorInput name={name} />
      </Popover>
    </div>
  );
}
