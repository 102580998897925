import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { SelectContactGroupsSchema } from "schemas";
import { Drawer, FormProvider } from "ui";
import { useContactGroup, useContactGroups } from "../../../store";
import { NewGroupForm } from "./NewGroupForm";
import { SelectGroupsForm } from "./SelectGroupsForm";

export function AddToList() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [addingGroup, setAddingGroup] = useState<boolean>(false);

  const onClose = () => {
    addingGroup ? setAddingGroup(false) : navigate(pathname.replace("/addToList", ""), { state: state });
  };

  const currentContactGroup = useContactGroup();
  const { contactGroups, loading } = useContactGroups(["AllContacts", "Unconfirmed", "Suppressed"]);
  const visibleContactGroups = contactGroups.filter((contactGroup) => contactGroup.id !== currentContactGroup?.id);
  const contactIds = state?.contactIds;

  if (loading) {
    return null;
  }

  if (!contactIds) {
    onClose();
    return;
  }
  const selectedCount = contactIds.length;

  return (
    <Drawer title={`Add ${selectedCount} contacts to list`} open onClose={onClose}>
      <FormProvider defaultValues={{ contactGroups: [] }} schema={SelectContactGroupsSchema}>
        {addingGroup ? (
          <NewGroupForm name="contactGroups" onDone={() => setAddingGroup(false)} />
        ) : (
          <SelectGroupsForm
            contactGroups={visibleContactGroups}
            contactIds={contactIds}
            onDone={onClose}
            onAddNewList={() => setAddingGroup(true)}
          />
        )}
      </FormProvider>
    </Drawer>
  );
}
