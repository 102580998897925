import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import type { PersistedCampaign } from "schemas";
import { apiRequest } from "../apiRequest";
import { useLiteSiteStore } from "../store";
import type { HookLoading } from "../types";
import { setCampaign } from "./setCampaign";

async function getCampaigns() {
  const campaigns = await apiRequest<PersistedCampaign[]>({ url: "campaigns" });
  campaigns.map(setCampaign);
}

export function useCampaigns(): HookLoading<{ campaigns: PersistedCampaign[] }> {
  const [loading, setLoading] = useState(true);
  const campaignsMap = useLiteSiteStore(({ campaigns }) => campaigns);
  const campaigns = Array.from(Object.values(campaignsMap)).sort(
    (a, b) => DateTime.fromISO(b.insertedAt).diff(DateTime.fromISO(a.insertedAt)).milliseconds,
  );

  useEffect(() => {
    if (loading) {
      getCampaigns().then(() => setLoading(false));
    }
  }, [loading]);

  return { loading, campaigns };
}
