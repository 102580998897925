import classNames from "classnames";
import type { PropsWithChildren } from "react";
import { type ButtonSize, type ButtonType, type PropsWithClassName, buttonStyles } from "ui";
import { Link } from "./Link";

interface Props extends PropsWithClassName, PropsWithChildren {
  buttonType?: ButtonType;
  size?: ButtonSize;
  to?: string;
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  onClick?: () => void;
  htmlButtonType?: "button" | "submit";
  preventScrollReset?: boolean;
}

export function Button({
  children,
  href,
  to,
  onClick,
  className,
  target,
  buttonType = "primary",
  size = "large",
  htmlButtonType = "button",
  disabled = false,
  preventScrollReset,
}: Props) {
  return (
    <Link
      target={target}
      className={classNames(
        buttonStyles.btn,
        {
          [buttonStyles.small]: size === "small",
          [buttonStyles.medium]: size === "medium",
          [buttonStyles.large]: size === "large",
          [buttonStyles.primary]: buttonType === "primary",
          [buttonStyles.secondary]: buttonType === "secondary",
          [buttonStyles.tertiary]: buttonType === "tertiary",
        },
        className,
      )}
      to={to}
      href={href}
      onClick={onClick}
      htmlButtonType={htmlButtonType}
      disabled={disabled}
      preventScrollReset={preventScrollReset}
    >
      {children}
    </Link>
  );
}
